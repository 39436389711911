// IE10&11 Flexbox fix
@media all and (-ms-high-contrast: none) {
  html {
    display: flex;
    flex-direction: column;
  }
}

// app-dashboard and app-root are Angular2+ selectors. You can add here your own selectors if you need.
.app,
app-dashboard,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  flex: 0 0 $navbar-height;
}

.app-footer {
  flex: 0 0 $footer-height;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  .main {
    flex: 1;
    min-width: 0;

    //background: $content-bg;

    padding: 0 30px;

    display: flex;

    & > div {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }

  h2,
  h3 {
    text-transform: capitalize;
  }

  h2 {
    padding: 1.5rem 0 1rem 0;
    font-size: 1.4rem;
  }

  h3 {
    padding: 1.15rem 0 1rem 0;
    font-size: 1rem;
  }

  .sidebar {
    // $sidebar-width is the width of the columns
    flex: 0 0 $sidebar-width;
    // put the nav on the left
    order: -1;
  }

  .aside-menu {
    // $aside-menu-width is the width of the columns
    flex: 0 0 $aside-menu-width;
  }
}

//
// header
//
.header-fixed {
  .app-header {
    position: fixed;
    z-index: $zindex-sticky;
    width: 100%;
  }
  .app-body {
    margin-top: $navbar-height;
  }
}

//
// Sidebar
//
.sidebar-hidden {
  .sidebar {
    margin-left: -$sidebar-width;
  }
}

.sidebar-fixed {
  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    width: auto;
    height: calc(100vh - #{$navbar-height});
    // margin-top: - $navbar-height;

    // .sidebar-nav {
    //   height: calc(100vh - #{$navbar-height});
    // }
  }

  .main,
  .app-footer {
    margin-left: $sidebar-width;
  }

  &.sidebar-hidden {
    .main,
    .app-footer {
      margin-left: 0;
    }
  }
}

.sidebar-off-canvas {
  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    height: calc(100vh - #{$navbar-height});
  }
}

@include media-breakpoint-down(sm) {
  .app-body {
    .main {
      padding: 0 10px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-compact {
    .sidebar {
      flex: 0 0 $sidebar-compact-width;
    }

    &.sidebar-hidden {
      .sidebar {
        margin-left: -$sidebar-compact-width;
      }
    }

    &.sidebar-fixed {
      .main,
      .app-footer {
        margin-left: $sidebar-compact-width;
      }

      .sidebar {
        width: auto;
      }

      &.sidebar-hidden {
        .main,
        .app-footer {
          margin-left: 0;
        }
      }
    }
    .sidebar-minimizer {
      display: none;
    }
  }

  .sidebar-minimized {
    .sidebar {
      flex: 0 0 $sidebar-minimized-width;
    }

    &.sidebar-hidden {
      .sidebar {
        margin-left: -$sidebar-minimized-width;
      }
    }

    &.sidebar-fixed {
      .main,
      .app-footer {
        margin-left: $sidebar-minimized-width;
      }

      .sidebar {
        width: auto;
      }

      &.sidebar-hidden {
        .main,
        .app-footer {
          margin-left: 0;
        }
      }
    }
  }
}

//
// Aside Menu
//
.aside-menu-hidden {
  .aside-menu {
    margin-right: -$aside-menu-width;
  }
}

.aside-menu-fixed {
  .aside-menu {
    position: fixed;
    right: 0;
    height: 100%;

    .tab-content {
      height: calc(
        100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height}
      );
    }
  }

  .main,
  .app-footer {
    margin-right: $aside-menu-width;
  }

  &.aside-menu-hidden {
    .main,
    .app-footer {
      margin-right: 0;
    }
  }
}

.aside-menu-off-canvas {
  .aside-menu {
    position: fixed;
    right: 0;
    z-index: $zindex-sticky - 1;
    height: 100%;

    .tab-content {
      height: calc(
        100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height}
      );
    }
  }
}

//
// Footer
//

.footer-fixed {
  .app-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-sticky;
    height: $footer-height;
  }

  .app-body {
    margin-bottom: $footer-height;
  }
}

// Alex

.cursor-pointer {
  cursor: pointer;
}

.form-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    padding-top: 6px;
  }

  &.form-group-checkbox {
    flex-direction: row !important;
    position: relative;
    margin-bottom: 5px;

    input {
      position: absolute;
      left: 20px;
      top: 0;
      width: auto !important;
    }
  }

  .dropdown-toggle {
    background: transparent !important;
    border: 1px solid $gray-300;
    border-radius: 8px;
  }

  .btn-group {
    .btn {
      background: transparent;
      border: 1px solid $gray-300;
      box-shadow: none !important;
    }
    .btn:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .btn:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .btn.active {
      background: $gray-200;
    }
    .fa,
    .fal,
    .far {
      color: $gray-400;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    width: 25%;

    @include media-breakpoint-down(md) {
      width: 30%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: left;
    }
  }

  .inputHolder {
    flex-direction: column;
    display: flex;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .inputHolder,
  input[type="text"],
  input[type="email"],
  textarea {
    flex: 1;
  }

  .form-control,
  .form-control-file,
  .form-control-range {
    width: auto;
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: left;
    }
  }
}

.overrideFormGroup {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
  margin-bottom: 1rem;

  label {
    width: 25%;

    @include media-breakpoint-down(md) {
      width: 30%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: left;
    }
  }

  div {
    flex: 1;
    width: auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: left;
    }

    &.is-invalid > div > div {
      border-color: #f86c6b !important;
    }
  }
}

.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b;
}

.inviteList {
  .inputHolder {
    width: 100%;
  }
}

.input-group-text.is-invalid {
  background: red;
  color: white;
}

form.fhir {
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 1;

    input,
    select,
    label,
    textarea,
    .inputHolder {
      width: 100%;
    }
  }
}

//
// Animations
//
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  transition: margin-left $layout-transition-speed,
    margin-right $layout-transition-speed, width $layout-transition-speed,
    flex $layout-transition-speed;
}
.sidebar-nav {
  transition: width $layout-transition-speed;
}
.breadcrumb {
  transition: left $layout-transition-speed, right $layout-transition-speed,
    width $layout-transition-speed;
}

//
// Mobile layout
//

@include media-breakpoint-down(md) {
  .app-body {
    margin-top: $navbar-height;
  }

  .breadcrumb-fixed {
    .main:nth-child(2) .breadcrumb {
      right: auto;
      left: auto;
      width: 100%;
    }
  }

  .sidebar {
    position: fixed;
    z-index: $zindex-sticky - 1;
    width: $mobile-sidebar-width;
    height: calc(100vh - #{$navbar-height});
    margin-left: -$mobile-sidebar-width;

    .sidebar-nav,
    .nav {
      width: $mobile-sidebar-width;
      min-height: calc(100vh - #{$navbar-height});
    }

    .sidebar-minimizer {
      display: none;
    }
  }

  .main,
  .app-footer {
    margin-left: 0 !important;
  }

  // .aside-menu {
  //   margin-right: - $aside-menu-width;
  // }

  .sidebar-hidden {
    .sidebar {
      margin-left: -$mobile-sidebar-width;
    }
  }

  .sidebar-mobile-show {
    .sidebar {
      width: $mobile-sidebar-width;
      margin-left: 0;
    }

    .main {
      margin-right: -$mobile-sidebar-width !important;
      margin-left: $mobile-sidebar-width !important;
    }
  }
}

.tabWithFilter {
  display: flex;
  justify-content: space-between;

  .filter {
    position: relative;
    input {
      font-size: 0.7rem;
      padding: 0.375rem 1.5rem 0.375rem 0.5rem;
    }
    .icon {
      position: absolute;
      right: 5px;
      top: 5px;
      span {
        color: #888;
      }
    }
  }
}

.dropdown-menu {
  transform: translate3d(0, 0, 0) !important;
  //top: 25px !important;
  top: 40px !important;
  /* left: 0px; */
  right: 5px !important;
  width: 100px !important;
  left: auto !important;
}

.shortDropdown {
  .dropdown-menu {
    right: auto !important;
    left: 0 !important;
    top: 30px !important;
  }
}

.dropdown-item {
  border-bottom: none !important;
  .badge {
    right: 10px;
  }
}
.badge {
  color: white;
}

.form-control:disabled {
  background: $gray-100;
}

.items {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn-add {
    text-transform: capitalize;
    font-weight: bold;
  }
}

.text-hidden-xs {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.text-cancel {
  margin-right: 15px !important;
  display: inline-block;
  font-size: 0.9em;
  cursor: pointer;
}

.tablePatients {
  table-layout: fixed;
  width: 100%;

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th.no {
    width: 20px;
  }

  th.actions {
    width: 100px;
  }
}

.profile {
  margin-top: 40px;
}

.profileImgHolder {
  width: 64px;
  height: 64px;
  display: flex;
  background: #f4f5f6;
  border-radius: 64px;
  justify-content: center;
  align-items: center;

  p {
    padding: 0;
    margin: 0;
    font-size: 32px;
    color: #959eb3;
    font-weight: 600;
  }
}

.statusesTimeline {
  //color: $gray-400;

  .date {
    border-left: 1px solid #ddd;
    font-size: 11px;
    position: relative;
    padding-left: 16px;
    padding-top: 10px;

    .today {
      position: absolute;
      right: 0px;
      top: 10px;
      color: $gray-500;
    }

    .circle {
      width: 16px;
      height: 16px;
      border-radius: 16px;
      border: 1px solid #dee2e6;
      background: white;
      position: absolute;
      left: -9px;
      top: 10px;
    }
  }

  .noLine {
    padding-top: 10px;
    border-left: none;
    .circle {
      left: -7px;
    }
  }

  .item {
    position: relative;
    border-left: 1px solid #dee2e6;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;

    .iconCircle {
      width: 22px;
      height: 22px;
      border-radius: 16px;
      border: 1px solid #dee2e6;
      background: white;
      position: absolute;
      left: -12px;
      top: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .textCircle {
      width: 16px;
      height: 16px;
      border-radius: 16px;
      border: 1px solid #dee2e6;
      background: white;
      position: absolute;
      left: -9px;
      top: 37px;
    }

    .icon {
      width: 10px;
      left: -1px;
    }

    .status {
      //box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08),
      //  0 6px 16px 0 rgba(0, 0, 0, 0.06);
      background-color: $white;
      border: 1px solid #dee2e6;
      padding: 20px;
      padding-bottom: 10px;

      .title {
        font-size: 1.1rem;
        line-height: 1.8rem;
      }
    }
  }

  .itemEnd {
    border-left: 1px solid #ddd;
    height: 15px;
  }
}

.status {
  .title {
    padding-bottom: 10px;
    font-size: 1.2rem;
    line-height: 2rem;
    color: black;
  }

  .senderInfo {
    font-size: 11px;
  }
}

.pagePatient {
  position: relative;
  .btnAddStatus {
    position: absolute;
    top: 1rem;
    right: 0;
  }
}

.backBtn {
  color: $gray-400;
  font-size: 1.3rem;
  position: absolute;
  cursor: pointer;
  top: 1.6rem;
}

.statusSingle {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  h2 {
    font-size: 1.2rem;
  }

  .status {
    display: flex;
    flex: 1;
    // flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    &.hasMedia {
      justify-content: space-between;
    }

    // @include media-breakpoint-down(lg) {
    //   flex-direction: column;
    //   &.hasMedia {
    //     justify-content: space-between;
    //   }

    //   .media {
    //     width: 100%;
    //     margin-right: 0%;
    //   }
    //   .text {
    //     margin-top: 20px;
    //     width: 100%;
    //     min-width: auto;
    //   }
    // }

    .media {
      position: relative;
      // width: 78%;
      width: 100%;
      margin-right: 0%;
      flex: 1;
      height: 100%;
      // margin-right: 2%;
      overflow: hidden;

      .thumbsHolder {
        position: absolute;
        bottom: 0;
        width: 100%;
        justify-content: center;
        display: flex;
        .bgImageUrl {
          cursor: pointer;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2),
            0 0 14px 0 rgba(0, 0, 0, 0.3);
        }
      }

      .fullSize.bgImageUrl {
        flex: 1;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        min-height: 400px;
      }
    }
    .text {
      // width: 20%;
      // min-width: 270px;
      margin-bottom: 20px;
      margin-top: 20px;
      width: 100%;
      min-width: auto;
    }
  }
}

.imageThumbs {
  .bgImageUrl {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 7px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    .active {
      position: absolute;
      box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.4);
      border-radius: 7px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 3px solid white;
    }
  }
}

.audioHolder,
.videoHolder {
  .progressHolder {
    flex: 1;
    width: 100%;
    padding-top: 5px;
    .playTime {
      background: transparent;
      font-size: 11px;
    }
    .progressBar {
      flex: 1;
      background: #eee;
      height: 4px;
      border-radius: 7px;
      max-height: 4px;
      margin-top: 5px;
    }
    .progressIndicator {
      background: black;
      height: 100%;
      border-radius: 7px;
      pointer-events: none;
    }
  }

  .btnPlay {
    width: 40px;
    height: 40px;
    border-radius: 7px;
    background-color: $gray-100;
    color: black;
    margin-right: 10px;
  }
}

.audioHolder {
  margin-bottom: 15px;
  min-height: 40px;
}

.videoHolder {
  background: black;
  flex: 1;
  width: 100%;
  height: 100%;

  .playTime {
    color: #fff;
  }
}

.text-red {
  color: red;
}

.errorInput .Select-control {
  border-color: red !important;
}

.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}

.groupsTitle {
  font-size: 12px;
  margin-left: 15px;
}

.btnToggle {
  margin-bottom: 1rem;
  cursor: pointer;

  u {
    margin-left: 5px;
  }
}

.modal-header {
  border-bottom: none;
  color: black;
}

.modal-body {
  margin-bottom: 10px;
}

.btnSettings {
  background: $gray-200;
  color: $gray-700;

  &:active,
  &:hover {
    background: $gray-200 !important;
  }
}

.nav-tabs {
  flex: 1;
  //margin-left: -30px;
  //margin-right: -30px;
  padding-left: 30px;

  // margin-bottom: 20px;

  .nav-link {
    border: none;
  }

  .nav-link.active,
  .nav-link.active:hover {
    font-weight: bold;
    border-bottom: 4px solid #06beb6;
  }
}

.tab-content {
  border-top: none !important;
}

.table-responsive {
  min-height: 500px;
}

.fhir-question-edit {
  .card-header {
    background: transparent;
  }
}

.btn-secondary {
  background: $gray-100;

  &:hover {
    background: $gray-100;
  }

  &:focus {
    box-shadow: none;
  }
}

.survey-create-group {
  background: lighten($yellow, 40%);
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 45px;
  padding-right: 45px;
  min-height: 300px;

  .card {
    background: "white" !important;
    flex: 1;
  }
}

.card {
  background: white;
}

.tableFieldsCustomize {
  .form-check-input {
    margin-left: 0;
  }
  .item * {
    font-size: 16px;
  }
  .draggable {
    margin-bottom: 10px;
  }
}

// .draggableWrap {
//   position: relative;
//   div {
//     left: 20px !important;
//     top: 165px !important;
//   }
// }

.draggable {
  // cursor: move; /* fallback if grab cursor is unsupported */
  // cursor: grab;
  // cursor: -moz-grab;
  // cursor: -webkit-grab;
  // input {
  //   cursor: pointer;
  // }
  cursor: pointer;
}

.draggable.placeholder,
.dragGhost {
  opacity: 0.5;
}

.draggable.dragged {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.customizeColumnRow {
  margin-bottom: 8px;
}

.checkBoxContainer {
  display: block;
  position: relative;
  padding-left: 35px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkBoxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkBoxContainer input:checked ~ .checkmark {
  background: #06beb6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #48b1bf,
    #06beb6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #48b1bf, #06beb6);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBoxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBoxContainer .checkmark:after {
  left: 8px;
  top: 4px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pageSubtitle {
  font-size: 0.9rem;
}

.Select {
  flex: 1;
  width: 100%;

  &.is-focused:not(.is-open) > .Select-control {
    border-color: #06beb6 !important;
  }
}
.Select-menu-outer {
  max-height: 500px !important;
}

.radioGroup {
  align-items: "flex-start";

  h3 {
    width: 25%;
  }
}

.colorPrimary {
  color: #06beb6;
}

.colorGray {
  color: $gray-200;
}

.rolloverRow:hover {
  // background: $gray-100;
}
.borderTop {
  border-top: 1px solid $gray-400;
}

.withFooter {
  flex: 1;
  height: 100%;
  padding-bottom: 70px;
}

.footerActions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #ededed;
}

.actionIcon {
  color: $gray-600;
  margin-right: 5px;
}

.fieldEditorWithTree {
  flex-grow: 1;

  .fieldTree {
    width: 35%;
    max-width: 350px;
    min-width: 300px;
    margin-right: 30px;
    margin-left: -30px;
    padding-left: 30px;
    padding-right: 15px;
    // border-top: 1px solid $gray-200;
    border-right: 1px solid $gray-200;

    .rstcustom__row {
      flex: 1;
      margin-right: 15px;
      font-size: 16px;
    }

    .rstcustom__rowLabel {
      width: 120px;
      overflow: hidden;
      margin-right: 0;
      padding-right: 0;
    }

    .rstcustom__toolbarButton {
      margin-left: 8px;
      cursor: pointer;
      color: $gray-600;

      &:hover {
        color: #06beb6;
      }
    }

    .rstcustom__rowContents .rstcustom__rowToolbar:first-child {
      display: none;
    }
  }

  .fieldEditor {
    display: flex;
    flex: 1;

    .fieldEdit {
      width: 100%;
    }
  }
}
:focus {
  outline: none;
}

.flex1 {
  flex: 1;
}

.upload {
  border: 1px dashed $gray-300;
  padding: 6px 8px;
}

.icon {
  width: 34px;
  height: 34px;
  background-position: 50% 50%;
}

.field-names {
  margin-right: 30px;
  text-align: right;
}

.field-names div {
  margin: 5px 0;
  font-weight: 600;
}

.field-values div {
  margin: 5px 0;
}

.list-group-item:hover,
.list-group-item:focus {
  background: #fff;
}

.rc-draggable-list-handles {
  display: none;
}

.fhir-questionnaire-question {
  margin-bottom: 20px;
}

.survey-link {
  cursor: pointer !important;
}

.survey-link-active {
  z-index: 2;
  color: #fff !important;
  background-color: #20a8d8;
  border-color: #20a8d8;
}

.survey-link-active:hover {
  z-index: 2;
  color: #fff !important;
  background-color: #20a8d8;
  border-color: #20a8d8;
}

.kanban-list-item {
  border-radius: 2px;
  border: 1px solid grey;
  background-color: #fff;
  box-shadow: none;
  padding: 8px;
  min-height: 40px;
  margin-bottom: 8px;
  user-select: none;
  transition: background-color 0.1s ease;
  color: #4d4d4d;
  display: flex;
  align-items: center;
}

.kanban-list-item:hover {
  color: #4d4d4d;
  text-decoration: none;
  cursor: pointer;
}

.kanban-list-item:focus {
  outline: 2px solid rebeccapurple;
  box-shadow: none;
}

.kanban-item-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
  flex-grow: 0;
}

.kanban-item-content {
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.kanban-item-blockQuote::before {
  content: open-quote;
}

.kanban-item-blockQuote::after {
  content: close-quote;
}

.kanban-item-footer {
  display: flex;
  margin-top: 8px;
}

.kanban-item-quoteId {
  flex-grow: 0;
  margin: 0;
}

.kanban-item-attribution {
  margin: 0;
  margin-left: 8px;
  text-align: right;
  flex-grow: 1;
}

.kanban-title {
  padding: 8px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
}

.kanban-title:focus {
  outline: 2px solid rebeccapurple;
  outline-offset: 2px;
}

// .rst__virtualScrollOverride {
//   overflow-x: auto !important;
//   overflow-y: auto !important;
// }

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: auto !important;
}

//.rstcustom__rowContents {
//  justify-content: start !important;
//}

/* Collection default theme */

.ReactVirtualized__Collection,
.ReactVirtualized__Collection__innerScrollContainer,
.ReactVirtualized__Grid,
.ReactVirtualized__Grid__innerScrollContainer,
.ReactVirtualized__Table,
.ReactVirtualized__Table__Grid {
}

/* Grid default theme */

/* Table default theme */

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ReactVirtualized__Table__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn {
  margin-right: 10px;
  min-width: 0px;
}

.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 24px;
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}

.rst__node {
  min-width: 100%;
  white-space: nowrap;
  position: relative;
  text-align: left;
}

.rst__nodeContent {
  position: absolute;
  top: 0;
  bottom: 0;
}

/* ==========================================================================
   Scaffold

    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */

.rst__lineBlock {
  height: 100%;
  position: relative;
  display: inline-block;
}

.rst__absoluteLineBlock {
  height: 100%;
  position: relative;
  display: inline-block;
  position: absolute;
  top: 0;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  position: absolute;
  content: "";
  background-color: black;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |     |
 * +-----+
 */

.rst__lineHalfHorizontalRight::before {
  height: 1px;
  top: 50%;
  right: 0;
  width: 50%;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */

.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  width: 1px;
  left: 50%;
  top: 0;
  height: 100%;
}

/**
 * +-----+
 * |  |  |
 * |  +  |
 * |     |
 * +-----+
 */

.rst__lineHalfVerticalTop::after {
  height: 50%;
}

/**
 * +-----+
 * |     |
 * |  +  |
 * |  |  |
 * +-----+
 */

.rst__lineHalfVerticalBottom::after {
  top: auto;
  bottom: 0;
  height: 50%;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */

.rst__highlightLineVertical {
  z-index: 3;
  &::before {
    position: absolute;
    content: "";
    background-color: #36c2f6;
    width: 8px;
    margin-left: -4px;
    left: 50%;
    top: 0;
    height: 100%;
  }
  &::after {
    content: "";
    position: absolute;
    height: 0;
    margin-left: -4px;
    left: 50%;
    top: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid white;
    -webkit-animation: arrow-pulse 1s infinite linear both;
    animation: arrow-pulse 1s infinite linear both;
  }
}

@-webkit-keyframes arrow-pulse {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }

  30% {
    -webkit-transform: translate(0, 300%);
    transform: translate(0, 300%);
    opacity: 1;
  }

  70% {
    -webkit-transform: translate(0, 700%);
    transform: translate(0, 700%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(0, 1000%);
    transform: translate(0, 1000%);
    opacity: 0;
  }
}

@keyframes arrow-pulse {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0;
  }

  30% {
    -webkit-transform: translate(0, 300%);
    transform: translate(0, 300%);
    opacity: 1;
  }

  70% {
    -webkit-transform: translate(0, 700%);
    transform: translate(0, 700%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(0, 1000%);
    transform: translate(0, 1000%);
    opacity: 0;
  }
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */

.rst__highlightTopLeftCorner::before {
  z-index: 3;
  content: "";
  position: absolute;
  border-top: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: calc(50% + 4px);
  top: 50%;
  margin-top: -4px;
  right: 0;
  width: calc(50% + 4px);
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */

.rst__highlightBottomLeftCorner {
  z-index: 3;
  &::before {
    content: "";
    position: absolute;
    border-bottom: solid 8px #36c2f6;
    border-left: solid 8px #36c2f6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: calc(100% + 4px);
    top: 0;
    right: 12px;
    width: calc(50% - 8px);
  }
  &::after {
    content: "";
    position: absolute;
    height: 0;
    right: 0;
    top: 100%;
    margin-top: -12px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #36c2f6;
  }
}

.rst__rowWrapper {
  padding: 10px 10px 10px 0;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.rst__row {
  height: 100%;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  > * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */

.rst__rowLandingPad,
.rst__rowCancelPad {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.rst__rowLandingPad > *,
.rst__rowCancelPad > * {
  opacity: 0 !important;
}

.rst__rowLandingPad::before {
  background-color: lightblue;
  border: 3px dashed white;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.rst__rowCancelPad::before {
  background-color: lightblue;
  border: 3px dashed white;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #e6a8ad;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */

/**
 * Nodes matching the search conditions are highlighted
 */

.rst__rowSearchMatch {
  outline: solid 3px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */

.rst__rowSearchFocus {
  outline: solid 3px #fc6421;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rst__rowContents {
  position: relative;
  height: 100%;
  border: solid #bbb 1px;
  border-left: none;
  -webkit-box-shadow: 0 2px 2px -2px;
  box-shadow: 0 2px 2px -2px;
  padding: 0 5px 0 10px;
  border-radius: 2px;
  min-width: 230px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: white;
}

.rst__rowContentsDragDisabled {
  border-left: solid #bbb 1px;
}

.rst__rowLabel {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding-right: 20px;
}

.rst__rowToolbar {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rst__moveHandle {
  height: 100%;
  width: 44px;
  background: #d9d9d9
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+")
    no-repeat center;
  border: solid #aaa 1px;
  -webkit-box-shadow: 0 2px 2px -2px;
  box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
}

.rst__loadingHandle {
  height: 100%;
  width: 44px;
  background: #d9d9d9
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+")
    no-repeat center;
  border: solid #aaa 1px;
  -webkit-box-shadow: 0 2px 2px -2px;
  box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
  cursor: default;
  background: #d9d9d9;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }
}

.rst__loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}

.rst__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  &::before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 11%;
    height: 30%;
    background-color: #fff;
    border-radius: 30%;
    -webkit-animation: pointFade 800ms infinite ease-in-out both;
    animation: pointFade 800ms infinite ease-in-out both;
  }
  &:nth-of-type(1) {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  &:nth-of-type(7) {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  &:nth-of-type(1)::before,
  &:nth-of-type(7)::before {
    -webkit-animation-delay: -800ms;
    animation-delay: -800ms;
  }
  &:nth-of-type(2) {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  &:nth-of-type(8) {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  &:nth-of-type(2)::before,
  &:nth-of-type(8)::before {
    -webkit-animation-delay: -666ms;
    animation-delay: -666ms;
  }
  &:nth-of-type(3) {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  &:nth-of-type(9) {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  &:nth-of-type(3)::before,
  &:nth-of-type(9)::before {
    -webkit-animation-delay: -533ms;
    animation-delay: -533ms;
  }
  &:nth-of-type(4) {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  &:nth-of-type(10) {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  &:nth-of-type(4)::before,
  &:nth-of-type(10)::before {
    -webkit-animation-delay: -400ms;
    animation-delay: -400ms;
  }
  &:nth-of-type(5) {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  &:nth-of-type(11) {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  &:nth-of-type(5)::before,
  &:nth-of-type(11)::before {
    -webkit-animation-delay: -266ms;
    animation-delay: -266ms;
  }
  &:nth-of-type(6) {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  &:nth-of-type(12) {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  &:nth-of-type(6)::before,
  &:nth-of-type(12)::before {
    -webkit-animation-delay: -133ms;
    animation-delay: -133ms;
  }
  &:nth-of-type(7) {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  &:nth-of-type(13) {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  &:nth-of-type(7)::before,
  &:nth-of-type(13)::before {
    -webkit-animation-delay: 0ms;
    animation-delay: 0ms;
  }
}

.rst__rowTitle {
  font-weight: bold;
}

.rst__rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.rst__rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 0 1px #000;
  box-shadow: 0 0 0 1px #000;
  width: 16px;
  height: 16px;
  padding: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.rst__collapseButton:focus,
.rst__expandButton:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
  box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
}

.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
  background-size: 24px;
  height: 20px;
  width: 20px;
}

.rst__collapseButton {
  background: #fff
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==")
    no-repeat center;
}

.rst__expandButton {
  background: #fff
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+")
    no-repeat center;
}

/**
 * Line for under a node with children
 */

.rst__lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;
  &::after {
    content: "";
    position: absolute;
    background-color: black;
    width: 1px;
    left: 50%;
    bottom: 0;
    height: 10px;
  }
}

.rst__placeholder {
  position: relative;
  height: 68px;
  max-width: 300px;
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  > * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  &::before {
    border: 3px dashed #d9d9d9;
    content: "";
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    z-index: -1;
  }
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */

.rst__placeholderLandingPad,
.rst__placeholderCancelPad {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.rst__placeholderLandingPad *,
.rst__placeholderCancelPad * {
  opacity: 0 !important;
}

.rst__placeholderLandingPad::before {
  background-color: lightblue;
  border-color: white;
}

.rst__placeholderCancelPad::before {
  background-color: lightblue;
  border-color: white;
  background-color: #e6a8ad;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */

/**
 * Extra class applied to VirtualScroll through className prop
 */

.rst__virtualScrollOverride {
  overflow: auto !important;
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.ReactVirtualized__Grid {
  outline: none;
}

/*# sourceMappingURL=style.css.map*/

.react-tel-input {
  position: relative;
  width: 110px;
  .ad {
    background-position: -16px 0;
  }
  .ae {
    background-position: -32px 0;
  }
  .af {
    background-position: -48px 0;
  }
  .ag {
    background-position: -64px 0;
  }
  .ai {
    background-position: -80px 0;
  }
  .al {
    background-position: -96px 0;
  }
  .am {
    background-position: -112px 0;
  }
  .ao {
    background-position: -128px 0;
  }
  .ar {
    background-position: -144px 0;
  }
  .as {
    background-position: -160px 0;
  }
  .at {
    background-position: -176px 0;
  }
  .au {
    background-position: -192px 0;
  }
  .aw {
    background-position: -208px 0;
  }
  .az {
    background-position: -224px 0;
  }
  .ba {
    background-position: -240px 0;
  }
  .bb {
    background-position: 0 -11px;
  }
  .bd {
    background-position: -16px -11px;
  }
  .be {
    background-position: -32px -11px;
  }
  .bf {
    background-position: -48px -11px;
  }
  .bg {
    background-position: -64px -11px;
  }
  .bh {
    background-position: -80px -11px;
  }
  .bi {
    background-position: -96px -11px;
  }
  .bj {
    background-position: -112px -11px;
  }
  .bm {
    background-position: -128px -11px;
  }
  .bn {
    background-position: -144px -11px;
  }
  .bo {
    background-position: -160px -11px;
  }
  .br {
    background-position: -176px -11px;
  }
  .bs {
    background-position: -192px -11px;
  }
  .bt {
    background-position: -208px -11px;
  }
  .bw {
    background-position: -224px -11px;
  }
  .by {
    background-position: -240px -11px;
  }
  .bz {
    background-position: 0 -22px;
  }
  .ca {
    background-position: -16px -22px;
  }
  .cd {
    background-position: -32px -22px;
  }
  .cf {
    background-position: -48px -22px;
  }
  .cg {
    background-position: -64px -22px;
  }
  .ch {
    background-position: -80px -22px;
  }
  .ci {
    background-position: -96px -22px;
  }
  .ck {
    background-position: -112px -22px;
  }
  .cl {
    background-position: -128px -22px;
  }
  .cm {
    background-position: -144px -22px;
  }
  .cn {
    background-position: -160px -22px;
  }
  .co {
    background-position: -176px -22px;
  }
  .cr {
    background-position: -192px -22px;
  }
  .cu {
    background-position: -208px -22px;
  }
  .cv {
    background-position: -224px -22px;
  }
  .cw {
    background-position: -240px -22px;
  }
  .cy {
    background-position: 0 -33px;
  }
  .cz {
    background-position: -16px -33px;
  }
  .de {
    background-position: -32px -33px;
  }
  .dj {
    background-position: -48px -33px;
  }
  .dk {
    background-position: -64px -33px;
  }
  .dm {
    background-position: -80px -33px;
  }
  .do {
    background-position: -96px -33px;
  }
  .dz {
    background-position: -112px -33px;
  }
  .ec {
    background-position: -128px -33px;
  }
  .ee {
    background-position: -144px -33px;
  }
  .eg {
    background-position: -160px -33px;
  }
  .er {
    background-position: -176px -33px;
  }
  .es {
    background-position: -192px -33px;
  }
  .et {
    background-position: -208px -33px;
  }
  .fi {
    background-position: -224px -33px;
  }
  .fj {
    background-position: -240px -33px;
  }
  .fk {
    background-position: 0 -44px;
  }
  .fm {
    background-position: -16px -44px;
  }
  .fo {
    background-position: -32px -44px;
  }
  .bl,
  .fr,
  .mf {
    background-position: -48px -44px;
  }
  .ga {
    background-position: -64px -44px;
  }
  .gb {
    background-position: -80px -44px;
  }
  .gd {
    background-position: -96px -44px;
  }
  .ge {
    background-position: -112px -44px;
  }
  .gf {
    background-position: -128px -44px;
  }
  .gh {
    background-position: -144px -44px;
  }
  .gi {
    background-position: -160px -44px;
  }
  .gl {
    background-position: -176px -44px;
  }
  .gm {
    background-position: -192px -44px;
  }
  .gn {
    background-position: -208px -44px;
  }
  .gp {
    background-position: -224px -44px;
  }
  .gq {
    background-position: -240px -44px;
  }
  .gr {
    background-position: 0 -55px;
  }
  .gt {
    background-position: -16px -55px;
  }
  .gu {
    background-position: -32px -55px;
  }
  .gw {
    background-position: -48px -55px;
  }
  .gy {
    background-position: -64px -55px;
  }
  .hk {
    background-position: -80px -55px;
  }
  .hn {
    background-position: -96px -55px;
  }
  .hr {
    background-position: -112px -55px;
  }
  .ht {
    background-position: -128px -55px;
  }
  .hu {
    background-position: -144px -55px;
  }
  .id {
    background-position: -160px -55px;
  }
  .ie {
    background-position: -176px -55px;
  }
  .il {
    background-position: -192px -55px;
  }
  .in {
    background-position: -208px -55px;
  }
  .io {
    background-position: -224px -55px;
  }
  .iq {
    background-position: -240px -55px;
  }
  .ir {
    background-position: 0 -66px;
  }
  .is {
    background-position: -16px -66px;
  }
  .it {
    background-position: -32px -66px;
  }
  .jm {
    background-position: -48px -66px;
  }
  .jo {
    background-position: -64px -66px;
  }
  .jp {
    background-position: -80px -66px;
  }
  .ke {
    background-position: -96px -66px;
  }
  .kg {
    background-position: -112px -66px;
  }
  .kh {
    background-position: -128px -66px;
  }
  .ki {
    background-position: -144px -66px;
  }
  .km {
    background-position: -160px -66px;
  }
  .kn {
    background-position: -176px -66px;
  }
  .kp {
    background-position: -192px -66px;
  }
  .kr {
    background-position: -208px -66px;
  }
  .kw {
    background-position: -224px -66px;
  }
  .ky {
    background-position: -240px -66px;
  }
  .kz {
    background-position: 0 -77px;
  }
  .la {
    background-position: -16px -77px;
  }
  .lb {
    background-position: -32px -77px;
  }
  .lc {
    background-position: -48px -77px;
  }
  .li {
    background-position: -64px -77px;
  }
  .lk {
    background-position: -80px -77px;
  }
  .lr {
    background-position: -96px -77px;
  }
  .ls {
    background-position: -112px -77px;
  }
  .lt {
    background-position: -128px -77px;
  }
  .lu {
    background-position: -144px -77px;
  }
  .lv {
    background-position: -160px -77px;
  }
  .ly {
    background-position: -176px -77px;
  }
  .ma {
    background-position: -192px -77px;
  }
  .mc {
    background-position: -208px -77px;
  }
  .md {
    background-position: -224px -77px;
  }
  .me {
    background-position: -112px -154px;
    height: 12px;
  }
  .mg {
    background-position: 0 -88px;
  }
  .mh {
    background-position: -16px -88px;
  }
  .mk {
    background-position: -32px -88px;
  }
  .ml {
    background-position: -48px -88px;
  }
  .mm {
    background-position: -64px -88px;
  }
  .mn {
    background-position: -80px -88px;
  }
  .mo {
    background-position: -96px -88px;
  }
  .mp {
    background-position: -112px -88px;
  }
  .mq {
    background-position: -128px -88px;
  }
  .mr {
    background-position: -144px -88px;
  }
  .ms {
    background-position: -160px -88px;
  }
  .mt {
    background-position: -176px -88px;
  }
  .mu {
    background-position: -192px -88px;
  }
  .mv {
    background-position: -208px -88px;
  }
  .mw {
    background-position: -224px -88px;
  }
  .mx {
    background-position: -240px -88px;
  }
  .my {
    background-position: 0 -99px;
  }
  .mz {
    background-position: -16px -99px;
  }
  .na {
    background-position: -32px -99px;
  }
  .nc {
    background-position: -48px -99px;
  }
  .ne {
    background-position: -64px -99px;
  }
  .nf {
    background-position: -80px -99px;
  }
  .ng {
    background-position: -96px -99px;
  }
  .ni {
    background-position: -112px -99px;
  }
  .bq,
  .nl {
    background-position: -128px -99px;
  }
  .no {
    background-position: -144px -99px;
  }
  .np {
    background-position: -160px -99px;
  }
  .nr {
    background-position: -176px -99px;
  }
  .nu {
    background-position: -192px -99px;
  }
  .nz {
    background-position: -208px -99px;
  }
  .om {
    background-position: -224px -99px;
  }
  .pa {
    background-position: -240px -99px;
  }
  .pe {
    background-position: 0 -110px;
  }
  .pf {
    background-position: -16px -110px;
  }
  .pg {
    background-position: -32px -110px;
  }
  .ph {
    background-position: -48px -110px;
  }
  .pk {
    background-position: -64px -110px;
  }
  .pl {
    background-position: -80px -110px;
  }
  .pm {
    background-position: -96px -110px;
  }
  .pr {
    background-position: -112px -110px;
  }
  .ps {
    background-position: -128px -110px;
  }
  .pt {
    background-position: -144px -110px;
  }
  .pw {
    background-position: -160px -110px;
  }
  .py {
    background-position: -176px -110px;
  }
  .qa {
    background-position: -192px -110px;
  }
  .re {
    background-position: -208px -110px;
  }
  .ro {
    background-position: -224px -110px;
  }
  .rs {
    background-position: -240px -110px;
  }
  .ru {
    background-position: 0 -121px;
  }
  .rw {
    background-position: -16px -121px;
  }
  .sa {
    background-position: -32px -121px;
  }
  .sb {
    background-position: -48px -121px;
  }
  .sc {
    background-position: -64px -121px;
  }
  .sd {
    background-position: -80px -121px;
  }
  .se {
    background-position: -96px -121px;
  }
  .sg {
    background-position: -112px -121px;
  }
  .sh {
    background-position: -128px -121px;
  }
  .si {
    background-position: -144px -121px;
  }
  .sk {
    background-position: -160px -121px;
  }
  .sl {
    background-position: -176px -121px;
  }
  .sm {
    background-position: -192px -121px;
  }
  .sn {
    background-position: -208px -121px;
  }
  .so {
    background-position: -224px -121px;
  }
  .sr {
    background-position: -240px -121px;
  }
  .ss {
    background-position: 0 -132px;
  }
  .st {
    background-position: -16px -132px;
  }
  .sv {
    background-position: -32px -132px;
  }
  .sx {
    background-position: -48px -132px;
  }
  .sy {
    background-position: -64px -132px;
  }
  .sz {
    background-position: -80px -132px;
  }
  .tc {
    background-position: -96px -132px;
  }
  .td {
    background-position: -112px -132px;
  }
  .tg {
    background-position: -128px -132px;
  }
  .th {
    background-position: -144px -132px;
  }
  .tj {
    background-position: -160px -132px;
  }
  .tk {
    background-position: -176px -132px;
  }
  .tl {
    background-position: -192px -132px;
  }
  .tm {
    background-position: -208px -132px;
  }
  .tn {
    background-position: -224px -132px;
  }
  .to {
    background-position: -240px -132px;
  }
  .tr {
    background-position: 0 -143px;
  }
  .tt {
    background-position: -16px -143px;
  }
  .tv {
    background-position: -32px -143px;
  }
  .tw {
    background-position: -48px -143px;
  }
  .tz {
    background-position: -64px -143px;
  }
  .ua {
    background-position: -80px -143px;
  }
  .ug {
    background-position: -96px -143px;
  }
  .us {
    background-position: -112px -143px;
  }
  .uy {
    background-position: -128px -143px;
  }
  .uz {
    background-position: -144px -143px;
  }
  .va {
    background-position: -160px -143px;
  }
  .vc {
    background-position: -176px -143px;
  }
  .ve {
    background-position: -192px -143px;
  }
  .vg {
    background-position: -208px -143px;
  }
  .vi {
    background-position: -224px -143px;
  }
  .vn {
    background-position: -240px -143px;
  }
  .vu {
    background-position: 0 -154px;
  }
  .wf {
    background-position: -16px -154px;
  }
  .ws {
    background-position: -32px -154px;
  }
  .ye {
    background-position: -48px -154px;
  }
  .za {
    background-position: -64px -154px;
  }
  .zm {
    background-position: -80px -154px;
  }
  .zw {
    background-position: -96px -154px;
  }
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .hide {
    display: none;
  }
  .v-hide {
    visibility: hidden;
  }
  input {
    &[type="tel"],
    &[type="text"] {
      position: relative;
      z-index: 0;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-left: 44px;
      margin-left: 0;
      background: #fff;
      border: 1px solid #cacaca;
      border-radius: 0px;
      -webkit-box-shadow: 0 1px 2px #e3e3e3 inset;
      box-shadow: inset 0 1px 2px #e3e3e3;
      line-height: 25px;
      height: 35px;
      width: 105px !important;
    }
    &[type="tel"]:focus,
    &[type="text"]:focus {
      border-color: #42bdff;
      border-left-color: #cacaca;
    }
    &[type="tel"].invalid-number,
    &[type="text"].invalid-number {
      border: 1px solid #d79f9f;
      background-color: #faf0f0;
      border-left-color: #cacaca;
      &:focus {
        border: 1px solid #d79f9f;
        background-color: #faf0f0;
        border-left-color: #cacaca;
      }
    }
  }
  .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #eaeaea;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
    &.open-dropdown {
      background: #fff;
      border-bottom: 0;
      border-radius: 3px 0 0 0;
      .selected-flag {
        background: #fff;
        border-radius: 3px 0 0 0;
      }
    }
    &:hover {
      cursor: pointer;
      .selected-flag {
        background-color: #fff;
      }
    }
  }
  input[disabled] + .flag-dropdown:hover {
    cursor: default;
    .selected-flag {
      background-color: transparent;
    }
  }
  .selected-flag {
    z-index: 13;
    position: relative;
    width: 38px;
    height: 33px;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;
    .flag {
      position: absolute;
      top: 50%;
      margin-top: -5px;
      width: 16px;
      height: 11px;
    }
    .arrow {
      position: relative;
      top: 50%;
      margin-top: -2px;
      left: 20px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555;
      &.up {
        border-top: none;
        border-bottom: 4px solid #555;
      }
    }
  }
  .country-list {
    list-style: none;
    position: absolute;
    z-index: 20;
    padding: 0;
    margin: -1px 0 0 -1px;
    -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: 1px solid #ccc;
    width: 400px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
    top: 26px;
    .flag {
      display: inline-block;
      margin-right: 6px;
      margin-top: 2px;
      width: 16px;
      height: 11px;
    }
    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
    }
    .country {
      padding: 5px 10px;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      .dial-code {
        color: #999;
      }
      &:hover {
        background-color: #e8f7fe;
      }
      &.highlight {
        background-color: #c7e2f1;
      }
    }
    .country-name {
      margin-right: 6px;
    }
  }
}
/*# sourceMappingURL=demo.b2f56175.css.map*/

.alert {
  //box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  //box-shadow: #ddd 0px 0px 2px 1px; //new
  background-color: #fff;
  border: 1px solid #e0e6e8;
  padding: 1rem 1.25rem;
  border-radius: 4px;
}

.alert-danger {
  //border-left: 4px solid #813838;
  //border: 1px solid #fdd9d7;
  background-color: #fdd9d7;
  border: 1px solid #fdd9d7;
  color: #813838;
}

.alert-success {
  //border-left: 4px solid #28623c;
  background-color: #dbefdc !important;
  color: #28623c;
  border: 1px solid #dbefdc;
}

.alert-info {
  border: 1px solid #ccf2f6;
  background-color: #ccf2f6;
  color: #00626e;
}

.pagePatient {
  flex-direction: row;
  @include media-breakpoint-down(sm) {
    flex-direction: column !important;
    align-items: flex-start;
  }
}

.patient-content-tabs {
  @include media-breakpoint-down(sm) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.careplan-progress {
  height: 0.7rem !important;
  border-radius: 3px !important;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #20a8d8;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 1.2em;
  //font-weight: bold;
  fill: #20a8d8;
}

.card-body-actions:hover {
  background-color: #f7f7f7;
}

.card-body-actions:after {
  content: "\2807";
  font-size: 18px;
}

.card-body-actions:focus {
  background-color: #f7f7f7;
  box-shadow: none;
}

.form-control:focus {
  border-color: #52bfb7;
  box-shadow: none;
}

.toaster-close-btn {
  position: relative !important;
  padding: 0 !important;
  margin-left: 10px;
}

.patient-profile-view {
  @include media-breakpoint-up(md) {
    border-right: 1px solid #dee2e6;
  }
}

.tab-nav-link {
  color: #151b1e;
  padding: 0.5rem;
  font-size: 0.8rem;
  display: block;
}

.patient-medical-info {
  @include media-breakpoint-up(md) {
    border-left: 1px solid #e0e6e8;
    border-right: 1px solid #e0e6e8;
  }
  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid #e0e6e8;
    padding: 10px 0;
    margin: 10px 0;
    border-top: 1px solid #e0e6e8;
  }
}

.patient-medical-info-l {
  @include media-breakpoint-up(md) {
    border-left: 1px solid #e0e6e8;
  }
  @include media-breakpoint-down(sm) {
    padding: 10px 0;
    margin: 10px 0;
    border-top: 1px solid #e0e6e8;
  }
}

.task-type-link-active {
  //z-index: 2;
  //color: #fff !important;
  //background-color: #20a8d8;
  //border-bottom: 2px solid #20a8d8;
  border: 2px solid #20a8d8 !important;
  //padding-bottom: 2px;
}

.stats-table td {
  border-top: 0px;
}

.filter-select {
  width: 25%;
}

/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

.react-calendar-heatmap {
  text {
    font-size: 10px;
    fill: #aaa;
  }
  .react-calendar-heatmap-small-text {
    font-size: 5px;
  }
  rect:hover {
    stroke: #555;
    stroke-width: 1px;
  }
  .color-empty {
    fill: #eeeeee;
  }
  .color-filled {
    fill: #8cc665;
  }
  .color-github-0 {
    fill: #eeeeee;
  }
  .color-github-1 {
    fill: #d6e685;
  }
  .color-github-2 {
    fill: #8cc665;
  }
  .color-github-3 {
    fill: #44a340;
  }
  .color-github-4 {
    fill: #1e6823;
  }
  .color-gitlab-0 {
    fill: #ededed;
  }
  .color-gitlab-1 {
    fill: #acd5f2;
  }
  .color-gitlab-2 {
    fill: #7fa8d1;
  }
  .color-gitlab-3 {
    fill: #49729b;
  }
  .color-gitlab-4 {
    fill: #254e77;
  }
}

.react-calendar-heatmap .color-scale-1 {
  fill: #d6e685;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #8cc665;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #44a340;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #1e6823;
}

.usage-stats-selector {
  width: 50%;
  flex: inherit;
}

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  //background-color: #e9e9e9;
  height: 10px;
  border-radius: 6px;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 10px;
  border-radius: 6px;
  //background-color: #abe2fb;
}

.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
  &:focus {
    border-color: #57c5f7;
    box-shadow: 0 0 0 5px #96dbfa;
    outline: none;
  }
}

.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}

.rc-slider-handle {
  &:hover {
    border-color: #57c5f7;
  }
  &:active {
    border-color: #57c5f7;
    box-shadow: 0 0 5px #57c5f7;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 10px;
  background: transparent;
}

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-dot-active {
  border-color: #96dbfa;
}

.rc-slider-disabled {
  background-color: #e9e9e9;
  .rc-slider-track {
    background-color: #ccc;
  }
  .rc-slider-handle,
  .rc-slider-dot {
    border-color: #ccc;
    box-shadow: none;
    background-color: #fff;
    cursor: not-allowed;
  }
  .rc-slider-mark-text,
  .rc-slider-dot {
    cursor: not-allowed !important;
  }
}

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
  .rc-slider-rail {
    height: 100%;
    width: 4px;
  }
  .rc-slider-track {
    left: 5px;
    bottom: 0;
    width: 4px;
  }
  .rc-slider-handle {
    margin-left: -5px;
    margin-bottom: -7px;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }
  .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
  }
  .rc-slider-step {
    height: 100%;
    width: 4px;
  }
  .rc-slider-dot {
    left: 2px;
    margin-bottom: -4px;
    &:first-child,
    &:last-child {
      margin-bottom: -4px;
    }
  }
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear,
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }

  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

.rc-slider-tooltip-hidden {
  display: none;
}

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

.btn-danger {
  color: #fff !important;
  border-color: #813838;
  background-color: #813838;
  cursor: pointer;
}

.btn-danger:hover {
  border-color: #81391d;
  background-color: #81391d;
}

.btn-group > .btn.active {
  z-index: 0;
}

@include media-breakpoint-down(sm) {
  .staff-head-usage {
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .staff-head-usage {
    width: 75%;
  }
}

.progress-list {
  display: flex;
  position: relative;
  margin-bottom: 1.25rem;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  font-weight: 400;
}

.progress-list > li {
  position: relative;
  width: 100%;
  text-align: center;
}

.progress-list > li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  width: 100%;
  height: 3px;
  background-color: rgba(34, 34, 48, 0.1);
}

.progress-list > li:last-child:before {
  display: none;
}

.progress-list > li > button {
  padding: 0;
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: #c6c9d5;
  color: rgba(34, 34, 48, 0.1);
  border: 4px solid #f6f7f9;
  vertical-align: middle;
  border-radius: 1rem;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: normal;
  z-index: 1;
  cursor: default;
}

.progress-list > li > button:active,
.progress-list > li > button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #346cb0;
}

.progress-list > .error > button,
.progress-list > .success > button {
  width: 24px;
  height: 24px;
}

.progress-list .progress-indicator {
  display: none;
  width: 1rem;
  height: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.progress-list .progress-label {
  display: inline-block;
  position: absolute;
  top: 1.75rem;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  font-size: 0.875rem;
  color: #888c9b;
}

.progress-list > .active:before {
  background-color: #346cb0;
}

.progress-list > .active > button {
  color: #346cb0;
  background-color: #346cb0;
  border-color: #346cb0;
}

.progress-list > .active > button:active,
.progress-list > .active > button:focus {
  box-shadow: 0 0 0 2px #346cb0;
}

.progress-list > .active .progress-indicator {
  color: #346cb0;
}

.progress-list > .active:before {
  background-color: rgba(34, 34, 48, 0.1);
}

.progress-list > .active > button {
  background-color: #fff;
}

.progress-list > .active .progress-label {
  color: #363642;
}

.progress-list > .success:before {
  background-color: #346cb0;
}

.progress-list > .success > button {
  color: #346cb0;
  background-color: #fff;
  border-color: #fff;
}

.progress-list > .success > button:active,
.progress-list > .success > button:focus {
  box-shadow: 0 0 0 2px #346cb0;
}

.progress-list > .success .progress-indicator {
  color: #346cb0;
}

.progress-list > .success .progress-label {
  color: #363642;
}

.progress-list > .error:before {
  background-color: #ea6759;
}

.progress-list > .error > button {
  color: #ea6759;
  background-color: #fff;
  border-color: #fff;
}

.progress-list > .error > button:active,
.progress-list > .error > button:focus {
  box-shadow: 0 0 0 2px #ea6759;
}

.progress-list > .error .progress-indicator {
  color: #ea6759;
}

.progress-list > .error:before {
  background-color: rgba(34, 34, 48, 0.1);
}

.progress-list > .active .progress-indicator,
.progress-list > .error .progress-indicator,
.progress-list > .success .progress-indicator {
  display: inline-block;
}

.progress-list > .success .progress-indicator {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300A28A' viewBox='0 0 24 24'%3E%3Cpath d='M12 .9C5.9.9.9 5.9.9 12s5 11.1 11.1 11.1 11.1-5 11.1-11.1S18.1.9 12 .9zm6.2 8.3l-7.1 7.2c-.3.3-.7.3-1 0l-3.9-3.9c-.2-.3-.2-.8 0-1.1l1-1c.3-.2.8-.2 1.1 0l2 2.1c.2.2.5.2.7 0l5.2-5.3c.2-.3.7-.3 1 0l1 1c.3.2.3.7 0 1z'/%3E%3C/svg%3E");
}

.progress-list > .error .progress-indicator {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23EA6759' viewBox='0 0 24 24'%3E%3Cpath xmlns='http://www.w3.org/2000/svg' d='M12 .9C5.9.9.9 5.9.9 12s5 11.1 11.1 11.1 11.1-5 11.1-11.1S18.1.9 12 .9zm2.3 11.5l3.6 3.6c.1.2.1.4 0 .6l-1.3 1.3c-.2.2-.5.2-.7 0l-3.6-3.6c-.2-.2-.4-.2-.6 0l-3.6 3.6c-.2.2-.5.2-.7 0l-1.3-1.3c-.1-.2-.1-.4 0-.6l3.6-3.6c.2-.2.2-.5 0-.7L6.1 8.1c-.2-.2-.2-.5 0-.7l1.3-1.3c.2-.1.4-.1.6 0l3.7 3.7c.2.2.4.2.6 0l3.6-3.6c.2-.2.5-.2.7 0l1.3 1.3c.1.2.1.4 0 .6l-3.6 3.6c-.2.2-.2.5 0 .7z'/%3E%3C/svg%3E");
}

.statusesTimeline .item .status,
.card {
  border: 1px solid #dddfe2;
  border-radius: 4px;
}

.card-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
//
//@include media-breakpoint-down(sm) {
//  .font-sm-size {
//    font-size: 20px;
//  }
//
//  .ob-logos {
//    display: flex;
//    flex-direction: column;
//    justify-content: center;
//    align-items: center;
//  }
//
//  .ob-fac-logo {
//    border-left: 0px !important;
//  }
//}

//@include media-breakpoint-up(md) {
.ob-logos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ob-fac-logo {
  border-left: 1px solid #e0e6e8 !important;
}
//}

.myp-terms-modal .modal-header .close {
  font-size: 30px;
  opacity: 1;
}

.form-group input[type="password"] {
  flex: 1;
}

.width-100 {
  width: 100% !important;
}

.stale-observation {
  font-style: italic;
  background-color: #f0f3f5;
  border-radius: 4px;
}

.row-card:hover {
  background-color: #f5f5f5;
}

.pt-filter-types-sel > .Select-menu-outer {
  height: 225px;
  max-height: 225px;
}

.pt-filter-types-sel > .Select-menu-outer > .Select-menu {
  height: 225px;
  max-height: 225px;
}

figure.md-block-image {
  margin: 10px 0;
  background: transparent !important;
  display: flex;
  justify-content: center;
}

//.md-block-header-one {
//  font-size: 2em;
//}
//.md-block-header-two {
//  font-size: 1.7em;
//}
//.md-block-header-three {
//  font-size: 1.3em;
//}
//.md-block-header-four {
//  font-size: 1em;
//}
//.md-block-header-five {
//  font-size: 0.8em;
//}
//.md-block-header-six {
//  font-size: 0.7em;
//}
//.md-block-header-five,
//.md-block-header-four,
//.md-block-header-one,
//.md-block-header-six,
//.md-block-header-three,
//.md-block-header-two {
//  margin-bottom: 5px;
//}
//.md-block-blockquote {
//  margin: 0;
//  padding-left: 15px;
//  border-left: 5px solid #4ca8de;
//  padding: 10px 0 10px 20px;
//  background-color: #e2f2ff;
//  font-size: 1.1em;
//}
//.md-block-image {
//  margin: 0 0 10px;
//}
//.md-block-image img {
//  //width: 100%;
//}
//
//.md-block-image figcaption {
//  text-align: center;
//  font-size: 0.9em;
//}
//.md-block-todo {
//  margin: 10px 0;
//}
//.md-block-todo input[type="checkbox"] {
//  float: left;
//  position: relative;
//  top: 0;
//  left: -2px;
//}
//.md-block-todo p {
//  margin-left: 18px;
//}
//.md-block-ordered-list-item,
//.md-block-unordered-list-item {
//  padding-left: 20px;
//}
//.md-block-ordered-list-item li,
//.md-block-unordered-list-item li {
//  margin-bottom: 5px;
//}
//.md-block-atomic-embed {
//  margin: 10px 0;
//}
//.md-block-atomic-break hr {
//  max-width: 100px;
//  border: 2px solid #ccc;
//}
//.md-inline-code {
//  font-family: Consolas, Liberation Mono, Menlo, Courier, monospace;
//  margin: 4px 0;
//  font-size: 0.9em;
//  padding: 1px 3px;
//  color: #555;
//  background-color: #fcfcfc;
//  border-width: 1px;
//  border-style: solid;
//  border-color: #ccc #ccc #bbb;
//  border-image: initial;
//  border-radius: 3px;
//  box-shadow: inset 0 -1px 0 #bbb;
//}
//.md-inline-link,
//.md-inline-link:visited {
//  color: #08c;
//}
//.md-inline-highlight {
//  background: #ff0;
//  display: inline;
//  padding: 2px 4px;
//}
//p.md-block-block-quote-caption {
//  margin-top: 0;
//  font-size: 0.8em;
//  padding-top: 5px;
//  padding-bottom: 5px;
//  padding-left: 10px;
//  background: #fafafa;
//}
//p.md-block-block-quote-caption:before {
//  content: "\2013";
//  color: #999;
//  float: left;
//  font-weight: 700;
//  display: inline;
//  margin-right: 10px;
//}

//input[type="date"] {
//  height: auto;
//}
//input[type="date"]:before {
//  content: attr(placeholder) !important;
//  color: #aaa;
//  margin-right: 0.5em;
//  display: inline-block;
//  width: 50%;
//  //display: inline-flex;
//}
//input[type="date"]:focus:before,
//input[type="date"]:valid:before {
//  content: "";
//}

.text-info {
  color: #20a8d8 !important;
}

/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
}

.DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8b9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em 0.6em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #4a90e2;
  color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f0f8ff;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.date-select.has-value.Select--single {
  .Select-control .Select-value .Select-value-label {
    font-weight: 500;
  }
}

.date-select .Select-clear-zone {
  color: #ddd;
}

.date-select .Select-arrow-zone {
  .Select-arrow {
    border-color: #ddd transparent transparent;
  }
}
.modal-header {
  color: #29363d;
}

.patient-model-header {
  //border-bottom: 2px solid #3c92a0;
  border-bottom: 1px solid #dee2e6;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.form-control {
  height: auto;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.0625rem + 2px);
}

.rbc-event.rbc-selected {
  background-color: #0e395f !important;
  font-weight: 600;
}
